import { ACTIONS, AspectsState, ScenariosAnalysisAction } from '../../types';

export const clearSelectedAspects = (): ScenariosAnalysisAction<AspectsState> => {
  return {
    type: ACTIONS.CLEAR_SELECTED_ASPECTS,
  };
};

export const setSelectedAspects = (aspectId: string): ScenariosAnalysisAction<AspectsState & { aspectId: string }> => {
  return {
    type: ACTIONS.SET_SELECTED_ASPECTS,
    data: { aspectId },
  };
};
