import { ACTIONS, AspectsState, ScenariosAnalysisAction } from '../../types';

const initialState: AspectsState = {};

export const reducer = (
  state = initialState,
  action: ScenariosAnalysisAction<AspectsState & { aspectId: string }>
): AspectsState => {
  switch (action.type) {
    case ACTIONS.CLEAR_SELECTED_ASPECTS:
      return { ...state, selectedAspects: undefined };
    case ACTIONS.SET_SELECTED_ASPECTS:
      return {
        ...state,
        selectedAspects: {
          ...state.selectedAspects,
          ...(action.data?.aspectId ? { [action.data.aspectId]: !state?.selectedAspects?.[action.data.aspectId] } : {}),
        },
      };
    default:
      return state;
  }
};
