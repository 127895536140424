import { Insight } from './insights';
import { Scenario, ScenarioAnchor, ScenarioCompany } from './scenarios';
import { Signal } from './signals';

export interface ScenariosAnalysisAction<T> {
  type: ACTIONS;
  data?: Partial<T>;
}

export interface SignalsState {
  loading: boolean;
  signals: Signal[];
  paginationToken?: string;
}

export interface InsightsState {
  loading: boolean;
  insights: Insight[];
}

export interface UserInputInsightsState extends InsightsState, SignalsState {
  submittedQuestion: string;
}

export interface PredictionsState extends InsightsState, SignalsState {
  signalsCount: number;
}

export interface MarketSizeState extends InsightsState, SignalsState {
  signalsCount: number;
}

export interface ScenariosState {
  loading: boolean;
  selected: string;
  scenarios: Scenario[];
}

export interface InputsState {
  loading: boolean;
  selectedAnchors: string[];
  selectedCompanies: string[];
  anchors: ScenarioAnchor[];
  companies: ScenarioCompany[];
}

export interface AspectsState {
  selectedAspects?: Record<string, boolean>;
}

export interface ScenariosAnalysisState {
  signals: SignalsState;
  qaInsights: InsightsState;
  marketResearchInsights: InsightsState;
  marketSizeInsights: MarketSizeState;
  predictionsInsights: PredictionsState;
  caseStudyInsights: InsightsState;
  swotInsights: InsightsState;
  userInputInsights: UserInputInsightsState;
  scenarios: ScenariosState;
  inputs: InputsState;
  aspects: AspectsState;
}

export enum ACTIONS {
  SIGNALS_PENDING = 'SIGNALS_PENDING',
  SIGNALS_COMPLETED = 'SIGNALS_COMPLETED',
  SIGNALS_SUCCESS = 'SIGNALS_SUCCESS',
  CLEAR_SIGNALS = 'CLEAR_SIGNALS',
  CLEAR_ALL_INSIGHTS = 'CLEAR_ALL_INSIGHTS',
  CLEAR_QA_INSIGHTS = 'CLEAR_QA_INSIGHTS',
  QA_INSIGHTS_PENDING = 'QA_INSIGHTS_PENDING',
  QA_INSIGHTS_COMPLETED = 'QA_INSIGHTS_COMPLETED',
  QA_INSIGHTS_SUCCESS = 'QA_INSIGHTS_SUCCESS',
  CLEAR_MARKET_RESEARCH_INSIGHTS = 'CLEAR_MARKET_RESEARCH_INSIGHTS',
  MARKET_RESEARCH_INSIGHTS_PENDING = 'MARKET_RESEARCH_INSIGHTS_PENDING',
  MARKET_RESEARCH_INSIGHTS_COMPLETED = 'MARKET_RESEARCH_INSIGHTS_COMPLETED',
  MARKET_RESEARCH_INSIGHTS_SUCCESS = 'MARKET_RESEARCH_INSIGHTS_SUCCESS',
  CLEAR_PREDICTIONS_INSIGHTS = 'CLEAR_PREDICTIONS_INSIGHTS',
  PREDICTIONS_INSIGHTS_PENDING = 'PREDICTIONS_INSIGHTS_PENDING',
  PREDICTIONS_INSIGHTS_COMPLETED = 'PREDICTIONS_INSIGHTS_COMPLETED',
  PREDICTIONS_INSIGHTS_SUCCESS = 'PREDICTIONS_INSIGHTS_SUCCESS',
  PREDICTIONS_SIGNALS_SUCCESS = 'PREDICTIONS_SIGNALS_SUCCESS',
  PREDICTIONS_SIGNALS_COUNT_SUCCESS = 'PREDICTIONS_SIGNALS_COUNT_SUCCESS',
  CLEAR_MARKET_SIZE_INSIGHTS = 'CLEAR_MARKET_SIZE_INSIGHTS',
  MARKET_SIZE_INSIGHTS_PENDING = 'MARKET_SIZE_INSIGHTS_PENDING',
  MARKET_SIZE_INSIGHTS_COMPLETED = 'MARKET_SIZE_INSIGHTS_COMPLETED',
  MARKET_SIZE_INSIGHTS_SUCCESS = 'MARKET_SIZE_INSIGHTS_SUCCESS',
  MARKET_SIZE_SIGNALS_SUCCESS = 'MARKET_SIZE_SIGNALS_SUCCESS',
  MARKET_SIZE_SIGNALS_COUNT_SUCCESS = 'MARKET_SIZE_SIGNALS_COUNT_SUCCESS',
  CLEAR_CASE_STUDY_INSIGHTS = 'CLEAR_CASE_STUDY_INSIGHTS',
  CASE_STUDY_INSIGHTS_PENDING = 'CASE_STUDY_INSIGHTS_PENDING',
  CASE_STUDY_INSIGHTS_COMPLETED = 'CASE_STUDY_INSIGHTS_COMPLETED',
  CASE_STUDY_INSIGHTS_SUCCESS = 'CASE_STUDY_INSIGHTS_SUCCESS',
  CLEAR_SWOT_INSIGHTS = 'CLEAR_SWOT_INSIGHTS',
  SWOT_INSIGHTS_PENDING = 'SWOT_INSIGHTS_PENDING',
  SWOT_INSIGHTS_COMPLETED = 'SWOT_INSIGHTS_COMPLETED',
  SWOT_INSIGHTS_SUCCESS = 'SWOT_INSIGHTS_SUCCESS',
  CLEAR_USER_INPUT_INSIGHTS = 'CLEAR_USER_INPUT_INSIGHTS',
  USER_INPUT_INSIGHTS_PENDING = 'USER_INPUT_INSIGHTS_PENDING',
  USER_INPUT_INSIGHTS_COMPLETED = 'USER_INPUT_INSIGHTS_COMPLETED',
  USER_INPUT_INSIGHTS_SUCCESS = 'USER_INPUT_INSIGHTS_SUCCESS',
  USER_INPUT_SIGNALS_SUCCESS = 'USER_INPUT_SIGNALS_SUCCESS',
  CLEAR_SCENARIOS = 'CLEAR_SCENARIOS',
  SCENARIOS_PENDING = 'SCENARIOS_PENDING',
  SCENARIOS_COMPLETED = 'SCENARIOS_COMPLETED',
  SCENARIOS_SUCCESS = 'SCENARIOS_SUCCESS',
  CLEAR_SELECTED_SCENARIO = 'CLEAR_SELECTED_SCENARIO',
  SET_SELECTED_SCENARIO = 'SET_SELECTED_SCENARIO',
  CLEAR_INPUTS = 'CLEAR_INPUTS',
  INPUTS_PENDING = 'INPUTS_PENDING',
  INPUTS_COMPLETED = 'INPUTS_COMPLETED',
  ANCHOR_SUCCESS = 'ANCHOR_SUCCESS',
  COMPANY_SUCCESS = 'COMPANY_SUCCESS',
  SET_SELECTED_ANCHORS = 'SET_SELECTED_ANCHORS',
  SET_SELECTED_COMPANIES = 'SET_SELECTED_COMPANIES',
  CLEAR_SELECTED_ANCHORS = 'CLEAR_SELECTED_ANCHORS',
  CLEAR_SELECTED_COMPANIES = 'CLEAR_SELECTED_COMPANIES',
  CLEAR_SELECTED_ASPECTS = 'CLEAR_SELECTED_ASPECTS',
  SET_SELECTED_ASPECTS = 'SET_SELECTED_ASPECTS',
}
